import React from "react"
import { Trans, useTranslation } from "gatsby-plugin-react-i18next"

import Layout from "../layouts/layout"
import SEO from "../layouts/seo"

const FaqPage = () => {
  const { t } = useTranslation()

  return (
    <Layout>
      <SEO title="FAQ" />

      <article className="article">

        <header className="article-header text--center">
          <h1 className="heading heading--md">{t("faq.header")}</h1>
        </header>

        <ul className="faq-list">
          <li>
            <h2>
              <Trans>faq.question-1</Trans>
            </h2>
            <ol>
              <li>
                <Trans>faq.answer-1-a</Trans>
              </li>
              <li>
                <Trans>faq.answer-1-b</Trans>
              </li>
              <li>
                <Trans>faq.answer-1-c</Trans>
              </li>
            </ol>
          </li>

          <li>
            <h2>
              <Trans>faq.question-2</Trans>
            </h2>
            <p>
              <Trans>faq.answer-2</Trans>
              <a href="mailto:ro-support@tiramizoo.com">ro-support@tiramizoo.com</a>
            </p>
          </li>

          <li>
            <h2>
              <Trans>faq.question-3</Trans>
            </h2>
            <p>
              <Trans>faq.answer-3</Trans>
            </p>
          </li>

          <li>
            <h2>
              <Trans>faq.question-4</Trans>
            </h2>
            <p>
              <Trans>faq.answer-4</Trans>
            </p>
          </li>

          <li>
            <h2>
              <Trans>faq.question-5</Trans>
            </h2>
            <p>
              <Trans>faq.answer-5</Trans>
              <a href="mailto:sales@tiramizoo.com">sales@tiramizoo.com</a>
            </p>
          </li>

          <li>
            <h2>
              <Trans>faq.question-6</Trans>
            </h2>
            <p>
              <Trans>faq.answer-6</Trans>
            </p>
          </li>

          <li>
            <h2>
              <Trans>faq.question-9</Trans>
            </h2>
            <p>
              <Trans>faq.answer-9</Trans>
            </p>
          </li>
        </ul>

      </article>
    </Layout>
  )
}
export default FaqPage
